<template>
	<v-dialog v-model="dialog" width="900px">
		<v-stepper v-model="step">
			<v-stepper-header>
				<v-stepper-step :complete="step > 1" step="1">
					Upload CSV
				</v-stepper-step>
				<v-divider></v-divider>
				<v-stepper-step :complete="step > 2" step="2">
					Confirm Emails
				</v-stepper-step>
				<v-divider></v-divider>

				<v-stepper-step :complete="step > 3" step="3">
					Preview and Send
				</v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<v-card>
						<v-card-text>
							<h3 class="mb-4">
								Send an email inviting your supporters to sign
								up for RoundUp App.
							</h3>
							<p>
								Upload a CSV file that includes at least one
								column labeled as "email".
							</p>
							<div
								style="display: flex; flex-direction: row; justify-content: flex-start; width: 100%"
							>
								<v-form
									v-model="valid"
									ref="fileInputForm"
									style="width: 50%"
								>
									<v-file-input
										accept=".csv"
										@change="handleFileChange"
									/>
								</v-form>
							</div>
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<RoundupButton
								:disabled="!emails"
								class="primary"
								@click="nextStep"
								>Next</RoundupButton
							>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
				<v-stepper-content step="2">
					<v-card>
						<v-card-text v-if="file">
							<h3 class="mb-4">
								Click to unselect emails from the list.
								Unselected emails will not be messaged.
							</h3>

							<div
								v-if="showFullList"
								class="chip-list"
								style="overflow-y: scroll !important; max-height: 320px"
							>
								<v-chip
									:class="{
										active: email.active
									}"
									@click="toggleChip"
									v-bind:key="email.name"
									class="ma-1"
									style="cursor: pointer"
									v-for="email in emails"
									>{{ email.name }}</v-chip
								>
								<span
									style="cursor: pointer; color: #2553cd"
									@click="toggleShow"
									v-if="leftoverEmailCount && showFullList"
								>
									... show less
								</span>
							</div>
							<div v-else class="chip-list">
								<v-chip
									@click="toggleChip"
									class="ma-1"
									:class="{
										active: email.active
									}"
									style="cursor: pointer"
									v-bind:key="email.name"
									v-for="email in truncatedEmailList"
									>{{ email.name }}</v-chip
								>
								<span
									style="cursor: pointer; color: #2553cd"
									@click="toggleShow"
									v-if="leftoverEmailCount && !showFullList"
								>
									... show {{ leftoverEmailCount }} more
								</span>
							</div>
							<v-checkbox
								v-model="checkbox"
								:label="
									`I acknowledge that I have permission to send marketing emails to ${
										emailsSelected === 1 ? 'this' : 'these'
									} ${emailsSelected} ${
										emailsSelected === 1
											? 'recipient'
											: 'recipients'
									}.`
								"
							></v-checkbox>
						</v-card-text>

						<v-card-actions>
							<RoundupButton @click="prevStep"
								>Back</RoundupButton
							>
							<v-spacer />
							<RoundupButton
								:disabled="!checkbox"
								class="primary"
								@click="nextStep"
								>Next</RoundupButton
							>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
				<v-stepper-content step="3">
					<v-card-text>
						<h3 class="mb-4">
							Preview your message and send! (Scroll to see entire
							message)
						</h3>
						<div style="max-height: 500px; overflow-y: scroll">
							<table
								border="0"
								cellpadding="0"
								cellspacing="0"
								class="nl-container"
								role="presentation"
								style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF;"
								width="100%"
							>
								<tbody>
									<tr>
										<td>
											<table
												align="center"
												border="0"
												cellpadding="0"
												cellspacing="0"
												class="row row-1"
												role="presentation"
												style="justify-content: center; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f6f8;"
												width="100%"
											>
												<tbody>
													<tr>
														<td>
															<table
																align="center"
																border="0"
																cellpadding="0"
																cellspacing="0"
																class="row-content stack"
																role="presentation"
																style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f6f8; color: #000000; width: 650px;"
																width="650"
															>
																<tbody>
																	<tr>
																		<td
																			class="column column-1"
																			style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 30px; padding-bottom: 30px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
																			width="100%"
																		>
																			<table
																				border="0"
																				cellpadding="0"
																				cellspacing="0"
																				class="image_block"
																				role="presentation"
																				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
																				width="100%"
																			>
																				<tr>
																					<td
																						style="width:100%;padding-right:0px;padding-left:0px;"
																					>
																						<div
																							align="center"
																							style="line-height:10px"
																						>
																							<a
																								href="https://roundupapp.com"
																								style="outline:none"
																								tabindex="-1"
																								target="_blank"
																								><img
																									src="https://mcusercontent.com/59f9c45adfafa085f45fd6a89/images/6987071d-93c5-729a-2567-dc916b210c3f.png"
																									style="display: block; height: auto; border: 0; width: 260px; max-width: 100%;"
																									width="260"
																							/></a>
																						</div>
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												align="center"
												border="0"
												cellpadding="0"
												cellspacing="0"
												class="row row-2"
												role="presentation"
												style="justify-content: center; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f6f8;"
												width="100%"
											>
												<tbody>
													<tr>
														<td>
															<table
																align="center"
																border="0"
																cellpadding="0"
																cellspacing="0"
																class="row-content stack"
																role="presentation"
																style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f6f8; color: #000000; width: 650px;"
																width="650"
															>
																<tbody>
																	<tr>
																		<td
																			class="column column-1"
																			style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 30px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
																			width="100%"
																		>
																			<table
																				border="0"
																				cellpadding="0"
																				cellspacing="0"
																				class="image_block"
																				role="presentation"
																				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
																				width="100%"
																			>
																				<tr>
																					<td
																						style="width:100%;padding-right:0px;padding-left:0px;"
																					>
																						<div
																							align="center"
																							style="line-height:10px"
																						>
																							<a
																								href="http://img.mailchimp.com/404-[PROFILE URL HERE]"
																								style="outline:none"
																								tabindex="-1"
																								target="_blank"
																								><img
																									alt="RoundUp App: create global change from your spare change"
																									class="big"
																									src="https://mcusercontent.com/59f9c45adfafa085f45fd6a89/images/ac8fc478-7e50-93fc-c317-0ead75e5b9a9.png"
																									style="display: block; height: auto; border: 0; width: 650px; max-width: 100%;"
																									title="RoundUp App: create global change from your spare change"
																									width="650"
																							/></a>
																						</div>
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												align="center"
												border="0"
												cellpadding="0"
												cellspacing="0"
												class="row row-3"
												role="presentation"
												style="justify-content: center; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f6f8;"
												width="100%"
											>
												<tbody>
													<tr>
														<td>
															<table
																align="center"
																border="0"
																cellpadding="0"
																cellspacing="0"
																class="row-content stack"
																role="presentation"
																style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 650px;"
																width="650"
															>
																<tbody>
																	<tr>
																		<td
																			class="column column-1"
																			style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-left: 30px; padding-right: 30px; padding-top: 30px; padding-bottom: 0px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
																			width="100%"
																		>
																			<table
																				border="0"
																				cellpadding="10"
																				cellspacing="0"
																				class="text_block"
																				role="presentation"
																				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
																				width="100%"
																			>
																				<tr>
																					<td>
																						<div
																							style="font-family: Georgia, 'Times New Roman', serif"
																						>
																							<div
																								style="font-size: 14px; font-family: 'Bitter', Georgia, Times, 'Times New Roman', serif; mso-line-height-alt: 16.8px; color: #000000; line-height: 1.2;"
																							>
																								<p
																									style="margin: 0; font-size: 14px;"
																								>
																									<span
																										style="font-size:34px;"
																										><strong
																											><span
																												style=""
																												>Introducing
																												RoundUp
																												App</span
																											></strong
																										></span
																									>
																								</p>
																							</div>
																						</div>
																					</td>
																				</tr>
																			</table>

																			<table
																				border="0"
																				cellpadding="10"
																				cellspacing="0"
																				class="text_block"
																				role="presentation"
																				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
																				width="100%"
																			>
																				<tr>
																					<td>
																						<div
																							style="font-family: sans-serif"
																						>
																							<div
																								style="font-size: 14px; mso-line-height-alt: 21px; color: #555555; line-height: 1.5; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;"
																							>
																								<p
																									style="margin: 0; font-size: 14px; mso-line-height-alt: 27px;"
																								>
																									<span
																										style="font-size:18px;"
																										>We’re
																										so
																										excited
																										to
																										announce
																										our
																										partnership
																										with
																										RoundUp
																										App
																										–
																										a
																										“spare
																										change”
																										platform
																										that
																										lets
																										you
																										donate
																										the
																										rounded
																										up
																										change
																										from
																										everyday
																										transactions.
																										With
																										RoundUp
																										App,
																										you
																										can
																										help
																										us
																										improve
																										our
																										community
																										without
																										even
																										changing
																										your
																										daily
																										routine.
																										<br /><br />All
																										you
																										have
																										to
																										do
																										is
																										<a
																											href="http://img.mailchimp.com/404-[PROFILE%20URL%20HERE]"
																											rel="noopener"
																											style="text-decoration: underline; color: #0068A5;"
																											target="_blank"
																											>create
																											an
																											account</a
																										>
																										and
																										connect
																										a
																										credit
																										or
																										debit
																										card.
																										It’s
																										that
																										easy!
																									</span>
																								</p>
																								<p
																									style="margin: 0; font-size: 14px; mso-line-height-alt: 21px;"
																								></p>
																								<p
																									style="margin: 0; font-size: 14px; mso-line-height-alt: 27px;"
																								>
																									<span
																										style="font-size:18px;"
																										>Plus,
																										RoundUp
																										App
																										uses
																										Stripe
																										to
																										process
																										transactions
																										(the
																										same
																										payment
																										processor
																										used
																										by
																										over
																										100,000
																										companies
																										including
																										trusted
																										names
																										like
																										Amazon
																										and
																										Lyft)
																										so
																										you
																										know
																										your
																										information
																										is
																										safe
																										and
																										secure.
																									</span>
																								</p>
																								<p
																									style="margin: 0; font-size: 14px; mso-line-height-alt: 27px;"
																								>
																									<span
																										style="font-size:18px;"
																										><br />Sincerely
																										{{
																											orgName
																										}}
																										<br /><br />P.S.
																										–
																										You
																										can
																										also
																										download
																										RoundUp
																										App
																										on
																										the
																										<a
																											href="https://apps.apple.com/us/app/roundup-app-donate-change/id1350497395?utm_source=Mailchimp&utm_medium=Email&utm_campaign=Invite-email-admins"
																											rel="noopener"
																											style="text-decoration: underline; color: #0068A5;"
																											target="_blank"
																											>App
																											Store</a
																										>
																										or
																										<a
																											href="https://roundup.gives/3tOPH5Y"
																											rel="noopener"
																											style="text-decoration: underline; color: #0068A5;"
																											target="_blank"
																											>Google
																											Play
																											Store!</a
																										>
																									</span>
																								</p>
																							</div>
																						</div>
																					</td>
																				</tr>
																			</table>

																			<table
																				border="0"
																				cellpadding="0"
																				cellspacing="0"
																				class="row button_block"
																				role="presentation"
																				style="justify-content: center; mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
																				width="100%"
																			>
																				<tr>
																					<td
																						style="text-align:left;padding-top:30px;padding-right:10px;padding-bottom:30px;padding-left:10px;"
																					>
																						<!--[if mso]><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="[link to nonprofit profile here]" style="height:52px;width:173px;v-text-anchor:middle;" arcsize="77%" stroke="false" fillcolor="#2553cd"><w:anchorlock/><v:textbox inset="0px,0px,0px,0px"><center style="color:#ffffff; font-family:Arial, sans-serif; font-size:16px"><!
																						[endif]--><a
																							href="http://img.mailchimp.com/404-[link to nonprofit profile here]"
																							style="text-decoration:none;display:inline-block;color:#ffffff;background-color:#2553cd;border-radius:40px;width:auto;border-top:1px solid #2553cd;border-right:1px solid #2553cd;border-bottom:1px solid #2553cd;border-left:1px solid #2553cd;padding-top:10px;padding-bottom:10px;font-family:Arial, 'Helvetica Neue', Helvetica, sans-serif;text-align:center;mso-border-alt:none;word-break:keep-all;"
																							target="_blank"
																							><span
																								style="padding-left:20px;padding-right:20px;font-size:16px;display:inline-block;letter-spacing:normal;"
																								><span
																									style="font-size: 16px; line-height: 2; word-break: break-word; mso-line-height-alt: 32px;"
																									>Join
																									RoundUp
																									App</span
																								></span
																							></a
																						>
																						<!--[if mso]></center></v:textbox></v:roundrect><![endif]-->
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												align="center"
												border="0"
												cellpadding="0"
												cellspacing="0"
												class="row row-4"
												role="presentation"
												style="justify-content: center; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f6f8;"
												width="100%"
											>
												<tbody>
													<tr>
														<td>
															<table
																align="center"
																border="0"
																cellpadding="0"
																cellspacing="0"
																class="row-content stack"
																role="presentation"
																style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #2553cd; color: #000000; width: 650px;"
																width="650"
															>
																<tbody>
																	<tr>
																		<td
																			class="column column-1"
																			style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 20px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
																			width="100%"
																		>
																			<table
																				border="0"
																				cellpadding="10"
																				cellspacing="0"
																				class="text_block"
																				role="presentation"
																				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
																				width="100%"
																			>
																				<tr>
																					<td>
																						<div
																							style="font-family: Georgia, 'Times New Roman', serif"
																						>
																							<div
																								style="font-size: 14px; font-family: 'Bitter', Georgia, Times, 'Times New Roman', serif; mso-line-height-alt: 16.8px; color: #ffffff; line-height: 1.2;"
																							>
																								<p
																									style="margin: 0; font-size: 14px; text-align: center;"
																								>
																									<span
																										style="font-size:30px;"
																										><strong
																											><span
																												style=""
																												>Download
																												RoundUp
																												App
																												for
																												your
																												device</span
																											></strong
																										></span
																									>
																								</p>
																							</div>
																						</div>
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												align="center"
												border="0"
												cellpadding="0"
												cellspacing="0"
												class="row row-5"
												role="presentation"
												style="justify-content: center; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f6f8;"
												width="100%"
											>
												<tbody>
													<tr>
														<td>
															<table
																align="center"
																border="0"
																cellpadding="0"
																cellspacing="0"
																class="row-content stack"
																role="presentation"
																style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #2553cd; color: #000000; width: 650px;"
																width="650"
															>
																<tbody>
																	<tr>
																		<td
																			class="column column-1"
																			style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
																			width="50%"
																		>
																			<table
																				border="0"
																				cellpadding="0"
																				cellspacing="0"
																				class="image_block"
																				role="presentation"
																				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
																				width="100%"
																			>
																				<tr>
																					<td
																						style="width:100%;padding-top:45px;padding-right:40px;padding-bottom:45px;padding-left:40px;"
																					>
																						<div
																							align="center"
																							style="line-height:10px"
																						>
																							<a
																								href="https://apps.apple.com/us/app/roundup-app-donate-change/id1350497395?utm_source=Mailchimp&utm_medium=Email&utm_campaign=Invite-email-admins"
																								style="outline:none"
																								tabindex="-1"
																								target="_blank"
																								><img
																									src="https://mcusercontent.com/59f9c45adfafa085f45fd6a89/images/174ed1fc-d9d1-460d-bc9a-2f1f55b9e522.png"
																									style="display: block; height: auto; border: 0; width: 211px; max-width: 100%;"
																									width="211"
																							/></a>
																						</div>
																					</td>
																				</tr>
																			</table>
																		</td>
																		<td
																			class="column column-2"
																			style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
																			width="50%"
																		>
																			<table
																				border="0"
																				cellpadding="0"
																				cellspacing="0"
																				class="image_block"
																				role="presentation"
																				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
																				width="100%"
																			>
																				<tr>
																					<td
																						style="width:100%;padding-top:35px;padding-right:40px;padding-bottom:45px;padding-left:40px;"
																					>
																						<div
																							align="center"
																							style="line-height:10px"
																						>
																							<a
																								href="https://roundup.gives/3tOPH5Y"
																								style="outline:none"
																								tabindex="-1"
																								target="_blank"
																								><img
																									src="https://mcusercontent.com/59f9c45adfafa085f45fd6a89/images/d373e416-749b-d340-24a9-91b850e54cf2.png"
																									style="display: block; height: auto; border: 0; width: 245px; max-width: 100%;"
																									width="245"
																							/></a>
																						</div>
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												align="center"
												border="0"
												cellpadding="0"
												cellspacing="0"
												class="row row-6"
												role="presentation"
												style="justify-content: center; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f6f8;"
												width="100%"
											>
												<tbody>
													<tr>
														<td>
															<table
																align="center"
																border="0"
																cellpadding="0"
																cellspacing="0"
																class="row-content stack"
																role="presentation"
																style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f5f6f8; color: #000000; width: 650px;"
																width="650"
															>
																<tbody>
																	<tr>
																		<td
																			class="column column-1"
																			style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 30px; padding-bottom: 30px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
																			width="100%"
																		>
																			<table
																				border="0"
																				cellpadding="0"
																				cellspacing="0"
																				class="image_block"
																				role="presentation"
																				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
																				width="100%"
																			>
																				<tr>
																					<td
																						style="width:100%;padding-right:0px;padding-left:0px;"
																					>
																						<div
																							align="center"
																							style="line-height:10px"
																						>
																							<a
																								href="https://roundupapp.com"
																								style="outline:none"
																								tabindex="-1"
																								target="_blank"
																								><img
																									src="https://mcusercontent.com/59f9c45adfafa085f45fd6a89/images/6987071d-93c5-729a-2567-dc916b210c3f.png"
																									style="display: block; height: auto; border: 0; width: 260px; max-width: 100%;"
																									width="260"
																							/></a>
																						</div>
																					</td>
																				</tr>
																			</table>

																			<table
																				border="0"
																				cellpadding="10"
																				cellspacing="0"
																				class="text_block"
																				role="presentation"
																				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
																				width="100%"
																			>
																				<tr>
																					<td>
																						<div
																							style="font-family: sans-serif"
																						>
																							<div
																								style="font-size: 14px; mso-line-height-alt: 16.8px; color: #555555; line-height: 1.2; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;"
																							>
																								<p
																									style="margin: 0; text-align: center;"
																								>
																									RoundUp
																									App<br />2802
																									Manor
																									Rd.,
																									Austin,
																									TX
																									78722,
																									United
																									States<br /><br /><br />You're
																									receiving
																									this
																									email
																									because
																									you
																									subscribed
																									or
																									signed
																									up
																									for
																									RoundUp
																									App.
																									If
																									you'd
																									no
																									longer
																									like
																									to
																									receive
																									these
																									emails,
																									you
																									can
																									<a
																										href="*|UNSUB|*"
																										rel="noopener"
																										style="text-decoration: underline; color: #0068A5;"
																										target="_blank"
																										>unsubscribe </a
																									>at
																									any
																									time
																									or
																									<a
																										href="https://us1.admin.mailchimp.com/campaigns/wizard/neapolitan/*|UPDATE_PROFILE|*"
																										rel="noopener"
																										style="text-decoration: underline; color: #0068A5;"
																										target="_blank"
																										>update
																										your
																										preferences.</a
																									><br /><br />
																								</p>
																								<p
																									style="margin: 0; text-align: center; mso-line-height-alt: 16.8px;"
																								></p>
																								<p
																									style="margin: 0; text-align: center; mso-line-height-alt: 16.8px;"
																								></p>
																							</div>
																						</div>
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												align="center"
												border="0"
												cellpadding="0"
												cellspacing="0"
												class="row row-7"
												role="presentation"
												style="justify-content: center; mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
												width="100%"
											>
												<tbody>
													<tr>
														<td>
															<table
																align="center"
																border="0"
																cellpadding="0"
																cellspacing="0"
																class="row-content stack"
																role="presentation"
																style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; color: #000000; width: 650px;"
																width="650"
															>
																<tbody>
																	<tr>
																		<td
																			class="column column-1"
																			style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
																			width="100%"
																		>
																			<table
																				border="0"
																				cellpadding="0"
																				cellspacing="0"
																				class="icons_block"
																				role="presentation"
																				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
																				width="100%"
																			>
																				<tr>
																					<td
																						style="vertical-align: middle; color: #9d9d9d; font-family: inherit; font-size: 15px; padding-bottom: 5px; padding-top: 5px; text-align: center;"
																					>
																						<table
																							cellpadding="0"
																							cellspacing="0"
																							role="presentation"
																							style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
																							width="100%"
																						>
																							<tr>
																								<td
																									style="vertical-align: middle; text-align: center;"
																								>
																									<!--[if vml]><table align="left" cellpadding="0" cellspacing="0" role="presentation" style="display:inline-block;padding-left:0px;padding-right:0px;mso-table-lspace: 0pt;mso-table-rspace: 0pt;"><![endif]-->
																									<!--[if !vml]><!-->
																									<table
																										cellpadding="0"
																										cellspacing="0"
																										class="icons-inner"
																										role="presentation"
																										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-block; margin-right: -4px; padding-left: 0px; padding-right: 0px;"
																									>
																										<!--<![endif]-->
																										<tr>
																											<td
																												style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 6px;"
																											>
																												<a
																													href="https://www.designedwithbee.com/"
																													style="text-decoration: none;"
																													target="_blank"
																													><img
																														align="center"
																														alt="Designed with BEE"
																														class="icon"
																														height="32"
																														src="https://mcusercontent.com/59f9c45adfafa085f45fd6a89/images/4982c0d2-ea84-6362-b802-945f26140bf3.png"
																														style="display: block; height: auto; margin: 0 auto; border: 0;"
																														width="34"
																												/></a>
																											</td>
																											<td
																												style="font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 15px; color: #9d9d9d; vertical-align: middle; letter-spacing: undefined; text-align: center;"
																											>
																												<a
																													href="https://www.designedwithbee.com/"
																													style="color: #9d9d9d; text-decoration: none;"
																													target="_blank"
																													>Designed
																													with
																													BEE</a
																												>
																											</td>
																										</tr>
																									</table>
																								</td>
																							</tr>
																						</table>
																					</td>
																				</tr>
																			</table>
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</v-card-text>
					<v-card-actions>
						<RoundupButton @click="prevStep">Back</RoundupButton>
						<v-spacer />
						<RoundupButton
							@click="onSend"
							:loading="loading"
							class="primary"
							>Send Emails!</RoundupButton
						>
					</v-card-actions>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</v-dialog>
</template>

<script>
import Papa from 'papaparse'
import _ from 'lodash'
import { mapActions } from 'vuex'
export default {
	name: 'csv-dialog',
	data() {
		return {
			dialog: false,
			loading: false,
			error: false,
			errorMessage: null,
			file: null,
			emails: null,
			showFullList: false,
			step: 1,
			valid: true,
			checkbox: false
		}
	},
	props: ['orgName'],
	computed: {
		truncatedEmailList() {
			if (this.file) {
				return this.emails.slice(0, 10)
			}
			return null
		},
		leftoverEmailCount() {
			if (this.file) {
				return this.emails.length - 10 > 0 ? this.emails.length - 10 : 0
			}
			return null
		},
		emailsSelected() {
			let count = 0
			for (var email of this.emails) {
				if (email.active) {
					count++
				}
			}
			return count
		}
	},
	methods: {
		...mapActions(['sendInviteDonorEmails']),
		toggle() {
			this.dialog = !this.dialog
		},
		nextStep() {
			this.step = this.step + 1 < 4 ? this.step + 1 : 4
		},
		prevStep() {
			this.step = this.step - 1 >= 1 ? this.step - 1 : 1
			if (this.step === 1) {
				this.reset()
			}
		},
		reset() {
			const elements = document.getElementsByClassName('fa-times-circle')
			if (elements[0]) elements[0].click()
			this.emails = null
			this.file = null
		},
		toggleChip(event) {
			window.analytics.track('upload-csv-user-email-excluded')

			try {
				this.emails = this.emails.map(email => {
					if (email.name === event.target.textContent) {
						email.active = !email.active
					}
					return email
				})
			} catch (e) {}
		},
		toggleShow() {
			this.showFullList = !this.showFullList
		},
		uploadCSV() {
			window.analytics.track('upload-csv-button-clicked')
			this.$refs.fileInput.click()
		},
		async onSend() {
			try {
				this.loading = true

				let activeEmails = this.emails
					.filter(e => e.active)
					.map(e => e.name)

				await this.sendInviteDonorEmails(activeEmails)

				this.loading = false
				this.dialog = false
				this.step = 1
				this.showFullList = false

				this.setAlert({
					alertText: 'Your emails will be sent shortly.',
					showAlert: true
				})

				this.$confetti.start()
				setTimeout(() => {
					this.$confetti.stop()
				}, 5000)
			} catch (e) {
				this.loading = false
				this.setAlert({
					alertText: 'Emails could not be sent. Please try again.',
					showAlert: true,
					alertColor: 'error'
				})
			}
		},
		handleFileChange(file) {
			if (!this.valid) {
				return
			}
			this.error = false
			this.errorMessage = null
			this.loading = true
			let vm = this

			Papa.parse(file, {
				complete: function(response) {
					try {
						const { data } = response
						const headerRow = data[0]
						// get the index for each column that includes 'email'
						let emailColumnIndex = []
						headerRow.forEach((e, i) => {
							if (e.toLowerCase().includes('email')) {
								emailColumnIndex.push(i)
							}
						})

						if (emailColumnIndex.length === 0) {
							vm.error = true
							vm.errorMessage =
								'Invalid CSV. Please ensure the title of your email column includes the word "email".'
							return
						}
						let emails = []
						data.forEach((row, rowInd) => {
							if (rowInd > 0) {
								for (var ind of emailColumnIndex) {
									let potentialEmail = row[ind]
									if (
										potentialEmail &&
										potentialEmail.includes('@')
									) {
										emails.push(potentialEmail)
									}
								}
							}
						})
						emails = _.uniq(emails)
						vm.emails = emails
							.sort()
							.map(e => ({ name: e, active: true }))

						vm.loading = false
						vm.file = file
						window.analytics.track('upload-csv-upload-success')
					} catch (e) {
						console.log(e)
						window.analytics.track('upload-csv-upload-failure')
						vm.error = true
						vm.errorMessage =
							'Your file could not be uploaded - please try again.'
						return
					}
				},
				error: function(e) {
					window.analytics.track('upload-csv-upload-failure')
					this.error = true
					this.errorMessage =
						'Your file could not be uploaded - please try again.'
				}
			})
		}
	},
	watch: {
		error: {
			handler(newValue) {
				if (newValue) {
					this.setAlert({
						alertText: this.errorMessage,
						showAlert: true,
						alertColor: 'error'
					})
					this.error = false
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.chip-list::v-deep .v-chip.active {
	background-color: #d9fdf5 !important;
	border: 1px solid #3df4cb !important;
}

.chip-list::v-deep .v-chip:not(.active) {
	background-color: #ffe7ea !important;
	border: 1px solid #ff6376 !important;
}
</style>
